import React from "react"

export default function Name () {
    return(
        <div>
            <h1>
                Show me the money
            </h1>
        </div>
    )
}
    
